import Task from 'models/Task';
import { IFormValuesTask } from '../TaskForm';
import { useSelector } from 'react-redux';
import {
  getDefaultStatusFromStore,
  getTaskFormValuesFromStore,
} from 'store/task/selectors';
import { NEW_TASK_KEY } from '../constants/NEW_TASK';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { useMemo } from 'react';

type UseGetTaskDefaultValuesProps = {
  taskPk?: number;
  initialValuesFromOrder?: IFormValuesTask;
  initialValuesFromParentTask?: IFormValuesTask | null;
  newTask: IFormValuesTask | Task;
  isDefaultAccountOrCounterparty: boolean;
};

function mergeValuesWithStatus(
  current: IFormValuesTask,
  statusDefault?: IClientSelectOptionV2
): IFormValuesTask;

function mergeValuesWithStatus(
  current?: IFormValuesTask,
  statusDefault?: IClientSelectOptionV2
): IFormValuesTask | undefined;

function mergeValuesWithStatus(
  current?: IFormValuesTask,
  statusDefault?: IClientSelectOptionV2
): IFormValuesTask | undefined {
  if (!current) {
    return undefined;
  }

  return { ...current, status: current?.status ?? statusDefault };
}

const getValuesForDefaultAccountOrCounterparty = (
  newTask: IFormValuesTask | Task,
  defaultStatus?: IClientSelectOptionV2,
  current?: IFormValuesTask
) => {
  const mergedValues = {
    ...current,
    account: newTask.account,
    counterparty: newTask?.counterparty,
  };

  return mergeValuesWithStatus(mergedValues, defaultStatus);
};

const useGetTaskDefaultValues = ({
  taskPk,
  newTask,
  initialValuesFromOrder,
  initialValuesFromParentTask,
  isDefaultAccountOrCounterparty,
}: UseGetTaskDefaultValuesProps): IFormValuesTask | Task => {
  const formTaskValues = useSelector(getTaskFormValuesFromStore);
  const taskId = taskPk ?? NEW_TASK_KEY;
  const defaultStatus = useSelector(getDefaultStatusFromStore);

  const { current, initial } = formTaskValues?.[taskId] || {};

  return useMemo(() => {
    if (initialValuesFromParentTask) {
      return mergeValuesWithStatus(initialValuesFromParentTask, defaultStatus);
    }

    if (initialValuesFromOrder) {
      return mergeValuesWithStatus(initialValuesFromOrder, defaultStatus);
    }

    if (isDefaultAccountOrCounterparty) {
      return getValuesForDefaultAccountOrCounterparty(
        newTask,
        defaultStatus,
        current
      );
    }

    return mergeValuesWithStatus(current, defaultStatus) || initial || newTask;
  }, [
    current,
    defaultStatus,
    initial,
    initialValuesFromOrder,
    initialValuesFromParentTask,
    isDefaultAccountOrCounterparty,
    newTask,
  ]);
};

export default useGetTaskDefaultValues;
