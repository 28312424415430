import {
  IExternalRequestFilters,
  IPaginatedParamsExternalRequest,
} from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import { composeClientSelectOptionsInNumbersArray } from 'services/utils/selects/composeIClientSelectOptionsInNumbers';
import trimStringInObject from 'services/utils/trimStringInObject/trimStringInObject';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { extractValueFromClientSelectOptionV2 } from '../../../../../../services/utils/selects/selects';
import config from 'config.json';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

type TKeysExternalRequestOptionsArray =
  | 'external_request_work_category_fk_array'
  | 'external_request_author_dk_array'
  | 'external_request_responsible_department_fk_array'
  | 'external_request_implementer_dk_array'
  | 'external_request_affiliate_fk_array'
  | 'external_request_status_fk_array'
  | 'external_request_event_fk_array'
  | 'external_request_billing_account_fk_array';

type TArrayExternalRequestOptions = Record<
  TKeysExternalRequestOptionsArray,
  number[]
>;

const EXTERNAL_REQUEST_KEY_ARRAY_OPTIONS: TKeysExternalRequestOptionsArray[] = [
  'external_request_author_dk_array',
  'external_request_responsible_department_fk_array',
  'external_request_work_category_fk_array',
  'external_request_implementer_dk_array',
  'external_request_affiliate_fk_array',
  'external_request_status_fk_array',
  'external_request_event_fk_array',
  'external_request_billing_account_fk_array',
];

const EXTERNAL_REQUEST_DELETE_KEY_PARAMS = [
  'isActiveImplementerDkArrayFilter',
  'isActivePkFilter',
  'isActiveBillingAccountFilter',
  'isActiveWorkCategoryFilter',
];

const EXTERNAL_REQUEST_EXCEPTIONS_KEY_PARAMS = ['external_request_is_pruned'];

class ExternalRequestConvertParamsBase {
  private readonly externalRequestFilters: IExternalRequestFilters;
  private readonly arrayOptions: TArrayExternalRequestOptions;
  private readonly isPrunedOn: boolean = config.isPrunedOn;

  constructor(externalRequestFilters: IExternalRequestFilters) {
    this.externalRequestFilters = externalRequestFilters;
    this.arrayOptions = this.getExternalRequestArrayOptions(
      externalRequestFilters
    );
  }

  public composeParamsGetExternalRequests =
    (): IPaginatedParamsExternalRequest => {
      const {
        external_request_supervisor_dk: supervisorDk,
        external_request_executive_implementer_dk: executiveImplementerDk,
        external_request_is_pruned: isPruned,
      } = this.externalRequestFilters;

      const composeNewParams = trimStringInObject({
        ...this.externalRequestFilters,
        ...this.arrayOptions,
        external_request_supervisor_dk: supervisorDk?.value
          ? supervisorDk.value
          : undefined,
        external_request_executive_implementer_dk: executiveImplementerDk?.value
          ? executiveImplementerDk.value
          : undefined,
        external_request_is_pruned: this.getIsPruned(isPruned),
      });

      return this.deleteUnnecessaryParametersInExternalRequestFilters(
        composeNewParams
      );
    };

  private getExternalRequestArrayOptions = (
    externalRequestFilters: IExternalRequestFilters
  ) => {
    return composeClientSelectOptionsInNumbersArray<
      IExternalRequestFilters,
      TArrayExternalRequestOptions
    >(externalRequestFilters, EXTERNAL_REQUEST_KEY_ARRAY_OPTIONS);
  };

  private deleteUnnecessaryParametersInExternalRequestFilters = (
    params: IPaginatedParamsExternalRequest
  ) =>
    deleteUnnecessaryParameters(
      params,
      EXTERNAL_REQUEST_DELETE_KEY_PARAMS,
      EXTERNAL_REQUEST_EXCEPTIONS_KEY_PARAMS
    );

  private getIsPruned = (
    option?: IClientSelectOptionV2<boolean>
  ): boolean | undefined => {
    return this.isPrunedOn
      ? extractValueFromClientSelectOptionV2(option)
      : false;
  };
}

export default ExternalRequestConvertParamsBase;
